// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var FaIcon = require("../shared/components/FaIcon.bs.js");
var ReactUtils = require("../shared/utils/ReactUtils.bs.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var EditorDrawer = require("../shared/components/EditorDrawer.bs.js");
var Notifications__List = require("./components/Notifications__List.bs.js");

function str(prim) {
  return prim;
}

function Notifications__Root(Props) {
  var wrapperClasses = Props.wrapperClasses;
  var buttonClasses = Props.buttonClasses;
  var iconClasses = Props.iconClasses;
  var hasNotifications = Props.hasNotifications;
  var title = Props.title;
  var icon = Props.icon;
  var match = React.useState(function () {
        return false;
      });
  var setShowNotifications = match[1];
  return React.createElement("div", {
              className: wrapperClasses
            }, ReactUtils.nullUnless(React.createElement(EditorDrawer.make, {
                      closeDrawerCB: (function (param) {
                          return Curry._1(setShowNotifications, (function (param) {
                                        return false;
                                      }));
                        }),
                      closeButtonTitle: "Schließen " + Belt_Option.getWithDefault(title, ""),
                      size: /* Small */0,
                      children: React.createElement(Notifications__List.make, {})
                    }), match[0]), React.createElement("button", {
                  className: buttonClasses,
                  title: "Benachrichtigungen zeigen",
                  onClick: (function (param) {
                      return Curry._1(setShowNotifications, (function (param) {
                                    return true;
                                  }));
                    })
                }, React.createElement(FaIcon.make, {
                      classes: Belt_Option.getWithDefault(icon, "")
                    }), Belt_Option.getWithDefault(title, ""), ReactUtils.nullUnless(React.createElement("span", {
                          className: iconClasses
                        }), hasNotifications)));
}

var make = Notifications__Root;

exports.str = str;
exports.make = make;
/* react Not a pure module */
