// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("rescript/lib/js/curry.js");
var Caml_option = require("rescript/lib/js/caml_option.js");

function $$default(e, v) {
  if (v !== undefined) {
    return Caml_option.valFromOption(v);
  } else {
    return e;
  }
}

function toString(option) {
  return $$default("", option);
}

function map(f, v) {
  if (v !== undefined) {
    return Caml_option.some(Curry._1(f, Caml_option.valFromOption(v)));
  }
  
}

function flatMap(f, v) {
  return $$default(undefined, map(f, v));
}

function mapWithDefault(f, d, v) {
  return $$default(d, map(f, v));
}

exports.$$default = $$default;
exports.default = $$default;
exports.__esModule = true;
exports.toString = toString;
exports.map = map;
exports.flatMap = flatMap;
exports.mapWithDefault = mapWithDefault;
/* No side effect */
