// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var User = require("../shared/types/User.bs.js");
var React = require("react");
var DomUtils = require("../shared/utils/DomUtils.bs.js");
var ReactDom = require("react-dom");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Json_decode = require("../@glennsl/bs-json/lib/ocaml/Json_decode.bs.js");
var StudentTopNav = require("../layouts/student/StudentTopNav.bs.js");
var StudentTopNav__NavLink = require("../layouts/student/types/StudentTopNav__Types/StudentTopNav__NavLink.bs.js");

function decodeProps(json) {
  return [
          Json_decode.field("schoolName", Json_decode.string, json),
          Caml_option.null_to_opt(Json_decode.field("logoUrl", (function (param) {
                      return Json_decode.nullable(Json_decode.string, param);
                    }), json)),
          Json_decode.field("links", (function (param) {
                  return Json_decode.array(StudentTopNav__NavLink.decode, param);
                }), json),
          Json_decode.field("isLoggedIn", Json_decode.bool, json),
          Json_decode.field("currentUser", (function (param) {
                  return Json_decode.optional(User.decode, param);
                }), json),
          Json_decode.field("hasNotifications", Json_decode.bool, json)
        ];
}

var match = decodeProps(DomUtils.parseJSONTag("student-top-nav-props", undefined));

var hasNotifications = match[5];

var currentUser = match[4];

var isLoggedIn = match[3];

var links = match[2];

var logoUrl = match[1];

var schoolName = match[0];

var element = document.querySelector("#student-top-nav");

if (!(element == null)) {
  ReactDom.render(React.createElement(StudentTopNav.make, {
            schoolName: schoolName,
            logoUrl: logoUrl,
            links: links,
            isLoggedIn: isLoggedIn,
            currentUser: currentUser,
            hasNotifications: hasNotifications
          }), element);
}

exports.decodeProps = decodeProps;
exports.schoolName = schoolName;
exports.logoUrl = logoUrl;
exports.links = links;
exports.isLoggedIn = isLoggedIn;
exports.currentUser = currentUser;
exports.hasNotifications = hasNotifications;
/* match Not a pure module */
