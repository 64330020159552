// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Js_exn = require("rescript/lib/js/js_exn.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Caml_exceptions = require("rescript/lib/js/caml_exceptions.js");
var Caml_js_exceptions = require("rescript/lib/js/caml_js_exceptions.js");

var ParseError = /* @__PURE__ */Caml_exceptions.create("Json.ParseError");

function parse(s) {
  try {
    return Caml_option.some(JSON.parse(s));
  }
  catch (exn){
    return ;
  }
}

function parseOrRaise(s) {
  try {
    return JSON.parse(s);
  }
  catch (raw_e){
    var e = Caml_js_exceptions.internalToOCamlException(raw_e);
    if (e.RE_EXN_ID === Js_exn.$$Error) {
      var m = e._1.message;
      var message = m !== undefined ? m : "Unknown error";
      throw {
            RE_EXN_ID: ParseError,
            _1: message,
            Error: new Error()
          };
    }
    throw e;
  }
}

var Decode;

var Encode;

function stringify(prim) {
  return JSON.stringify(prim);
}

exports.Decode = Decode;
exports.Encode = Encode;
exports.ParseError = ParseError;
exports.parse = parse;
exports.parseOrRaise = parseOrRaise;
exports.stringify = stringify;
/* No side effect */
