// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("rescript/lib/js/curry.js");

function Make(Item) {
  var toArray = function (t) {
    if (typeof t === "number") {
      return [];
    } else {
      return t._0;
    }
  };
  var update = function (t, fn) {
    if (typeof t === "number") {
      return /* Unloaded */0;
    } else if (t.TAG === /* PartiallyLoaded */0) {
      return {
              TAG: /* PartiallyLoaded */0,
              _0: Curry._1(fn, t._0),
              _1: t._1
            };
    } else {
      return {
              TAG: /* FullyLoaded */1,
              _0: Curry._1(fn, t._0)
            };
    }
  };
  var make = function (items, hasNextPage, endCursor) {
    if (hasNextPage && endCursor !== undefined) {
      return {
              TAG: /* PartiallyLoaded */0,
              _0: items,
              _1: endCursor
            };
    } else {
      return {
              TAG: /* FullyLoaded */1,
              _0: items
            };
    }
  };
  return {
          toArray: toArray,
          update: update,
          make: make
        };
}

exports.Make = Make;
/* No side effect */
