// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var I18n = require("./utils/I18n.bs.js");
var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var FaIcon = require("./components/FaIcon.bs.js");
var Js_exn = require("rescript/lib/js/js_exn.js");
var Js_dict = require("rescript/lib/js/js_dict.js");
var Js_json = require("rescript/lib/js/js_json.js");
var Js_option = require("rescript/lib/js/js_option.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var GraphqlQuery = require("./utils/GraphqlQuery.bs.js");
var $$Notification = require("./Notification.bs.js");
var WebPushSubscription = require("./webPushSubscription");

var partial_arg = "components.WebPushSubscriptionManager";

function t(param, param$1, param$2) {
  return I18n.t(partial_arg, param, param$1, param$2);
}

function getWebPushData(prim) {
  return WebPushSubscription.getWebPushData();
}

function str(prim) {
  return prim;
}

function reducer(state, action) {
  switch (action) {
    case /* SetStatusSubscribed */0 :
        return {
                saving: false,
                status: /* Subscribed */0
              };
    case /* SetStatusUnSubscribed */1 :
        return {
                saving: false,
                status: /* UnSubscribed */1
              };
    case /* SetStatusSubscribedOnAnotherDevice */2 :
        return {
                saving: false,
                status: /* SubscribedOnAnotherDevice */2
              };
    case /* SetSaving */3 :
        return {
                saving: true,
                status: state.status
              };
    case /* ClearSaving */4 :
        return {
                saving: false,
                status: state.status
              };
    
  }
}

var ppx_printed_query = "mutation CreateWebPushSubscriptionMutation($endpoint: String!, $p256dh: String!, $auth: String!)  {\ncreateWebPushSubscription(endpoint: $endpoint, p256dh: $p256dh, auth: $auth)  {\nsuccess  \n}\n\n}\n";

function parse(value) {
  var value$1 = Js_option.getExn(Js_json.decodeObject(value));
  var value$2 = Js_dict.get(value$1, "createWebPushSubscription");
  var tmp;
  if (value$2 !== undefined) {
    var value$3 = Js_option.getExn(Js_json.decodeObject(Caml_option.valFromOption(value$2)));
    var value$4 = Js_dict.get(value$3, "success");
    var tmp$1;
    if (value$4 !== undefined) {
      var value$5 = Caml_option.valFromOption(value$4);
      var value$6 = Js_json.decodeBoolean(value$5);
      tmp$1 = value$6 !== undefined ? value$6 : Js_exn.raiseError("graphql_ppx: Expected boolean, got " + JSON.stringify(value$5));
    } else {
      tmp$1 = Js_exn.raiseError("graphql_ppx: Field success on type CreateWebPushSubscriptionPayload is missing");
    }
    tmp = {
      success: tmp$1
    };
  } else {
    tmp = Js_exn.raiseError("graphql_ppx: Field createWebPushSubscription on type Mutation is missing");
  }
  return {
          createWebPushSubscription: tmp
        };
}

function make(endpoint, p256dh, auth, param) {
  return {
          query: ppx_printed_query,
          variables: Js_dict.fromArray([
                  [
                    "endpoint",
                    endpoint
                  ],
                  [
                    "p256dh",
                    p256dh
                  ],
                  [
                    "auth",
                    auth
                  ]
                ].filter(function (param) {
                    return !Js_json.test(param[1], /* Null */5);
                  })),
          parse: parse
        };
}

function makeWithVariables(variables) {
  var endpoint = variables.endpoint;
  var p256dh = variables.p256dh;
  var auth = variables.auth;
  return {
          query: ppx_printed_query,
          variables: Js_dict.fromArray([
                  [
                    "endpoint",
                    endpoint
                  ],
                  [
                    "p256dh",
                    p256dh
                  ],
                  [
                    "auth",
                    auth
                  ]
                ].filter(function (param) {
                    return !Js_json.test(param[1], /* Null */5);
                  })),
          parse: parse
        };
}

function makeVariables(endpoint, p256dh, auth, param) {
  return Js_dict.fromArray([
                [
                  "endpoint",
                  endpoint
                ],
                [
                  "p256dh",
                  p256dh
                ],
                [
                  "auth",
                  auth
                ]
              ].filter(function (param) {
                  return !Js_json.test(param[1], /* Null */5);
                }));
}

function definition_2(graphql_ppx_use_json_variables_fn, endpoint, p256dh, auth, param) {
  return Curry._1(graphql_ppx_use_json_variables_fn, Js_dict.fromArray([
                    [
                      "endpoint",
                      endpoint
                    ],
                    [
                      "p256dh",
                      p256dh
                    ],
                    [
                      "auth",
                      auth
                    ]
                  ].filter(function (param) {
                      return !Js_json.test(param[1], /* Null */5);
                    })));
}

var definition = [
  parse,
  ppx_printed_query,
  definition_2
];

function ret_type(f) {
  return {};
}

var MT_Ret = {};

var CreateWebPushSubscriptionMutation = {
  ppx_printed_query: ppx_printed_query,
  query: ppx_printed_query,
  parse: parse,
  make: make,
  makeWithVariables: makeWithVariables,
  makeVariables: makeVariables,
  definition: definition,
  ret_type: ret_type,
  MT_Ret: MT_Ret
};

var ppx_printed_query$1 = "mutation DeleteWebPushSubscriptionMutation  {\ndeleteWebPushSubscription  {\nsuccess  \n}\n\n}\n";

function parse$1(value) {
  var value$1 = Js_option.getExn(Js_json.decodeObject(value));
  var value$2 = Js_dict.get(value$1, "deleteWebPushSubscription");
  var tmp;
  if (value$2 !== undefined) {
    var value$3 = Js_option.getExn(Js_json.decodeObject(Caml_option.valFromOption(value$2)));
    var value$4 = Js_dict.get(value$3, "success");
    var tmp$1;
    if (value$4 !== undefined) {
      var value$5 = Caml_option.valFromOption(value$4);
      var value$6 = Js_json.decodeBoolean(value$5);
      tmp$1 = value$6 !== undefined ? value$6 : Js_exn.raiseError("graphql_ppx: Expected boolean, got " + JSON.stringify(value$5));
    } else {
      tmp$1 = Js_exn.raiseError("graphql_ppx: Field success on type DeleteWebPushSubscriptionPayload is missing");
    }
    tmp = {
      success: tmp$1
    };
  } else {
    tmp = Js_exn.raiseError("graphql_ppx: Field deleteWebPushSubscription on type Mutation is missing");
  }
  return {
          deleteWebPushSubscription: tmp
        };
}

function make$1(param) {
  return {
          query: ppx_printed_query$1,
          variables: null,
          parse: parse$1
        };
}

function makeWithVariables$1(param) {
  return {
          query: ppx_printed_query$1,
          variables: null,
          parse: parse$1
        };
}

function makeVariables$1(param) {
  return null;
}

function definition_2$1(graphql_ppx_use_json_variables_fn) {
  return 0;
}

var definition$1 = [
  parse$1,
  ppx_printed_query$1,
  definition_2$1
];

function ret_type$1(f) {
  return {};
}

var MT_Ret$1 = {};

var DeleteWebPushSubscriptionMutation = {
  ppx_printed_query: ppx_printed_query$1,
  query: ppx_printed_query$1,
  parse: parse$1,
  make: make$1,
  makeWithVariables: makeWithVariables$1,
  makeVariables: makeVariables$1,
  definition: definition$1,
  ret_type: ret_type$1,
  MT_Ret: MT_Ret$1
};

function deleteSubscription(send, $$event) {
  $$event.preventDefault();
  Curry._1(send, /* SetSaving */3);
  GraphqlQuery.sendQuery(undefined, make$1(undefined)).then(function (response) {
          if (response.deleteWebPushSubscription.success) {
            Curry._1(send, /* SetStatusUnSubscribed */1);
          } else {
            Curry._1(send, /* ClearSaving */4);
          }
          return Promise.resolve(undefined);
        }).catch(function (param) {
        Curry._1(send, /* ClearSaving */4);
        return Promise.resolve(undefined);
      });
  
}

function saveSubscription(subscription, send) {
  Curry._1(send, /* SetSaving */3);
  GraphqlQuery.sendQuery(undefined, make(subscription.endpoint, subscription.p256dh, subscription.auth, undefined)).then(function (response) {
          if (response.createWebPushSubscription.success) {
            Curry._1(send, /* SetStatusSubscribed */0);
          } else {
            Curry._1(send, /* ClearSaving */4);
          }
          return Promise.resolve(undefined);
        }).catch(function (param) {
        Curry._1(send, /* ClearSaving */4);
        return Promise.resolve(undefined);
      });
  
}

function handleNotificationBlock(param) {
  return $$Notification.error(t(undefined, undefined, "notification_rejected"), t(undefined, undefined, "notification_rejected_message"));
}

function createSubscription(send, $$event) {
  $$event.preventDefault();
  WebPushSubscription.createSubscription().then(function (r) {
          if (r == null) {
            handleNotificationBlock(undefined);
          } else {
            saveSubscription(r, send);
          }
          return Promise.resolve(undefined);
        }).catch(function (param) {
        Curry._1(send, /* ClearSaving */4);
        handleNotificationBlock(undefined);
        return Promise.resolve(undefined);
      });
  
}

var webPushEndpoint = document.documentElement.getAttribute("data-subscription-endpoint");

var webPushEndpoint$1 = (webPushEndpoint == null) ? undefined : Caml_option.some(webPushEndpoint);

function loadStatus(status, send, param) {
  if (status !== 1) {
    WebPushSubscription.getWebPushData().then(function (r) {
            if ((webPushEndpoint == null) || webPushEndpoint === "") {
              Curry._1(send, /* SetStatusUnSubscribed */1);
            } else if (r == null) {
              Curry._1(send, /* SetStatusSubscribedOnAnotherDevice */2);
            } else {
              Curry._1(send, webPushEndpoint === r.endpoint ? /* SetStatusSubscribed */0 : /* SetStatusSubscribedOnAnotherDevice */2);
            }
            return Promise.resolve(undefined);
          }).catch(function (param) {
          return Promise.resolve(undefined);
        });
  }
  
}

function computeInitialState(param) {
  return {
          saving: false,
          status: Belt_Option.mapWithDefault(webPushEndpoint$1, /* UnSubscribed */1, (function (_u) {
                  return /* Subscribed */0;
                }))
        };
}

function button(saving, onClick, icon, text) {
  return React.createElement("button", {
              className: "inline-flex items-center font-semibold px-2 py-1 md:py-1 bg-gray-200 hover:bg-gray-300 border rounded text-xs flex-shrink-0",
              disabled: saving,
              onClick: onClick
            }, React.createElement(FaIcon.make, {
                  classes: "mr-2 fa-fw fas fa-" + (
                    saving ? "spinner fa-spin" : icon
                  )
                }), text);
}

function WebPushSubscriptionManager(Props) {
  var match = React.useReducer(reducer, computeInitialState(undefined));
  var send = match[1];
  var state = match[0];
  var partial_arg = state.status;
  React.useEffect((function () {
          var param;
          return loadStatus(partial_arg, send, param);
        }), []);
  var match$1 = state.status;
  switch (match$1) {
    case /* Subscribed */0 :
        return button(state.saving, (function (param) {
                      return deleteSubscription(send, param);
                    }), "bell-slash", t(undefined, undefined, "unsubscribe"));
    case /* UnSubscribed */1 :
        return button(state.saving, (function (param) {
                      return createSubscription(send, param);
                    }), "bell", t(undefined, undefined, "subscribe"));
    case /* SubscribedOnAnotherDevice */2 :
        return React.createElement("div", undefined, button(state.saving, (function (param) {
                          return createSubscription(send, param);
                        }), "bell", t(undefined, undefined, "subscribed_on_another_device")));
    
  }
}

var make$2 = WebPushSubscriptionManager;

exports.t = t;
exports.getWebPushData = getWebPushData;
exports.str = str;
exports.reducer = reducer;
exports.CreateWebPushSubscriptionMutation = CreateWebPushSubscriptionMutation;
exports.DeleteWebPushSubscriptionMutation = DeleteWebPushSubscriptionMutation;
exports.deleteSubscription = deleteSubscription;
exports.saveSubscription = saveSubscription;
exports.handleNotificationBlock = handleNotificationBlock;
exports.createSubscription = createSubscription;
exports.webPushEndpoint = webPushEndpoint$1;
exports.loadStatus = loadStatus;
exports.computeInitialState = computeInitialState;
exports.button = button;
exports.make = make$2;
/* webPushEndpoint Not a pure module */
