// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Caml_option = require("rescript/lib/js/caml_option.js");
var StringUtils = require("./utils/StringUtils.bs.js");

function initials(name) {
  return name.split(" ").slice(0, 2).map(function (word) {
                return word.slice(0, 1);
              }).join("");
}

function Avatar(Props) {
  var colors = Props.colors;
  var name = Props.name;
  var className = Props.className;
  var match = colors !== undefined ? [
      colors[0],
      colors[1]
    ] : StringUtils.toColor(name);
  var tmp = {
    title: name,
    version: "1.1",
    viewBox: "0 0 100 100",
    xmlns: "http://www.w3.org/2000/svg"
  };
  if (className !== undefined) {
    tmp.className = Caml_option.valFromOption(className);
  }
  return React.createElement("svg", tmp, React.createElement("circle", {
                  cx: "50",
                  cy: "50",
                  fill: match[0],
                  r: "50"
                }), React.createElement("text", {
                  alignmentBaseline: "middle",
                  dominantBaseline: "middle",
                  fill: match[1],
                  fontFamily: "sans-serif",
                  fontSize: "42",
                  textAnchor: "middle",
                  x: "50",
                  y: "54"
                }, initials(name)));
}

var make = Avatar;

exports.initials = initials;
exports.make = make;
/* react Not a pure module */
