// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var User = require("../../shared/types/User.bs.js");
var DateFns = require("../../shared/utils/DateFns.bs.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");

function make(actor, createdAt, $$event, id, message, notifiableId, notifiableType, readAt) {
  return {
          actor: actor,
          createdAt: createdAt,
          event: $$event,
          id: id,
          message: message,
          notifiableId: notifiableId,
          notifiableType: notifiableType,
          readAt: readAt
        };
}

function actor(t) {
  return t.actor;
}

function createdAt(t) {
  return t.createdAt;
}

function $$event(t) {
  return t.event;
}

function id(t) {
  return t.id;
}

function message(t) {
  return t.message;
}

function notifiableId(t) {
  return t.notifiableId;
}

function notifiableType(t) {
  return t.notifiableType;
}

function readAt(t) {
  return t.readAt;
}

function decodeEvent($$event) {
  if ($$event === "PostCreated") {
    return /* PostCreated */1;
  } else {
    return /* TopicCreated */0;
  }
}

function makeFromJS(entry) {
  return make(Belt_Option.map(entry.actor, User.makeFromJs), DateFns.decodeISO(entry.createdAt), decodeEvent(entry.event), entry.id, entry.message, entry.notifiableId, entry.notifiableType, Belt_Option.map(entry.readAt, DateFns.decodeISO));
}

function markAsRead(entry) {
  return {
          actor: entry.actor,
          createdAt: entry.createdAt,
          event: entry.event,
          id: entry.id,
          message: entry.message,
          notifiableId: entry.notifiableId,
          notifiableType: entry.notifiableType,
          readAt: Caml_option.some(DateFns.parseISO(Date.now().toString()))
        };
}

exports.make = make;
exports.actor = actor;
exports.createdAt = createdAt;
exports.$$event = $$event;
exports.id = id;
exports.message = message;
exports.notifiableId = notifiableId;
exports.notifiableType = notifiableType;
exports.readAt = readAt;
exports.decodeEvent = decodeEvent;
exports.makeFromJS = makeFromJS;
exports.markAsRead = markAsRead;
/* User Not a pure module */
