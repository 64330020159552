// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Json = require("../../@glennsl/bs-json/lib/ocaml/Json.bs.js");
var Curry = require("rescript/lib/js/curry.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Caml_exceptions = require("rescript/lib/js/caml_exceptions.js");

var DataElementMissing = /* @__PURE__ */Caml_exceptions.create("DomUtils.DataElementMissing");

var RootElementMissing = /* @__PURE__ */Caml_exceptions.create("DomUtils.RootElementMissing");

var RootAttributeMissing = /* @__PURE__ */Caml_exceptions.create("DomUtils.RootAttributeMissing");

function parseJSONTag(idOpt, param) {
  var id = idOpt !== undefined ? idOpt : "react-root-data";
  var rootElement = document.getElementById(id);
  var tmp;
  if (rootElement == null) {
    throw {
          RE_EXN_ID: DataElementMissing,
          _1: id,
          Error: new Error()
        };
  }
  tmp = rootElement.innerHTML;
  return Json.parseOrRaise(tmp);
}

function parseJSONAttribute(idOpt, attributeOpt, param) {
  var id = idOpt !== undefined ? idOpt : "react-root";
  var attribute = attributeOpt !== undefined ? attributeOpt : "data-json-props";
  var rootElement = document.getElementById(id);
  var tmp;
  if (rootElement == null) {
    throw {
          RE_EXN_ID: RootElementMissing,
          _1: id,
          Error: new Error()
        };
  }
  var props = rootElement.getAttribute(attribute);
  if (props == null) {
    throw {
          RE_EXN_ID: RootAttributeMissing,
          _1: attribute,
          Error: new Error()
        };
  }
  tmp = props;
  return Json.parseOrRaise(tmp);
}

function redirect(path) {
  window.location = path;
  
}

function reload(param) {
  window.location.reload();
  
}

function isDevelopment(param) {
  var props = document.documentElement.getAttribute("data-env");
  if (props == null) {
    return false;
  } else {
    return props === "development";
  }
}

function goBack(param) {
  window.history.back();
  
}

function getUrlParam(key) {
  return Caml_option.nullable_to_opt(new URLSearchParams(window.location.search).get(key));
}

function hasUrlParam(key) {
  return Belt_Option.isSome(getUrlParam(key));
}

var $$FormData = {};

var $$EventTarget = {};

var $$Event = {};

function clearFileInput(inputId, callBack, param) {
  var e = document.getElementById(inputId);
  if (!(e == null)) {
    e.value = "";
  }
  return Belt_Option.mapWithDefault(callBack, undefined, (function (cb) {
                return Curry._1(cb, undefined);
              }));
}

var $$Element = {
  clearFileInput: clearFileInput
};

exports.DataElementMissing = DataElementMissing;
exports.RootElementMissing = RootElementMissing;
exports.RootAttributeMissing = RootAttributeMissing;
exports.parseJSONTag = parseJSONTag;
exports.parseJSONAttribute = parseJSONAttribute;
exports.redirect = redirect;
exports.reload = reload;
exports.isDevelopment = isDevelopment;
exports.goBack = goBack;
exports.getUrlParam = getUrlParam;
exports.hasUrlParam = hasUrlParam;
exports.$$FormData = $$FormData;
exports.$$EventTarget = $$EventTarget;
exports.$$Event = $$Event;
exports.$$Element = $$Element;
/* No side effect */
