// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Js_dict = require("rescript/lib/js/js_dict.js");

function mergeOptionsAndVariables(options, variables) {
  return Object.assign(options, variables);
}

function options(count, identifier) {
  var defaultScope = Js_dict.fromArray([[
          "scope",
          "shared." + identifier
        ]]);
  return {
          count: count,
          defaults: [defaultScope]
        };
}

function t(scope, variablesOpt, count, identifier) {
  var variables = variablesOpt !== undefined ? variablesOpt : [];
  var fullOptions = mergeOptionsAndVariables(options(count, identifier), Js_dict.fromArray(variables));
  var fullIdentifier = scope !== undefined ? scope + ("." + identifier) : identifier;
  return i18n.t(fullIdentifier, fullOptions);
}

function ts(variablesOpt, count, identifier) {
  var variables = variablesOpt !== undefined ? variablesOpt : [];
  return t("shared", variables, count, identifier);
}

exports.t = t;
exports.ts = ts;
/* No side effect */
