// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var I18n = require("../../../shared/utils/I18n.bs.js");
var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var StudentTopNav__NavLink = require("../types/StudentTopNav__Types/StudentTopNav__NavLink.bs.js");

function str(prim) {
  return prim;
}

var partial_arg = "components.StudentTopNav__DropDown";

function t(param, param$1, param$2) {
  return I18n.t(partial_arg, param, param$1, param$2);
}

function handleToggle(setLinksVisible, param) {
  return Curry._1(setLinksVisible, (function (linksVisible) {
                return !linksVisible;
              }));
}

function additionalLinks(linksVisible, links) {
  if (linksVisible) {
    return React.createElement("div", {
                className: "dropdown__list dropdown__list-right bg-white shadow-lg rounded mt-3 border absolute w-40 z-50"
              }, links.map(function (link, index) {
                    return React.createElement("div", {
                                key: String(index),
                                className: ""
                              }, React.createElement("a", {
                                    className: "cursor-pointer block p-3 text-xs font-semibold text-gray-900 border-b border-gray-200 bg-white hover:text-primary-500 hover:bg-gray-200",
                                    href: StudentTopNav__NavLink.url(link),
                                    rel: "noopener",
                                    target: "_blank"
                                  }, StudentTopNav__NavLink.title(link)));
                  }));
  } else {
    return null;
  }
}

function StudentTopNav__DropDown(Props) {
  var links = Props.links;
  var match = React.useState(function () {
        return false;
      });
  var setLinksVisible = match[1];
  if (links.length !== 0) {
    return React.createElement("div", {
                key: "more-links",
                className: "ml-2 font-semibold text-sm p-4 md:px-3 md:py-2 cursor-pointer relative rounded-lg text-gray-900 hover:bg-gray-200 hover:text-primary-500",
                title: "Show more links",
                onClick: (function (param) {
                    return Curry._1(setLinksVisible, (function (linksVisible) {
                                  return !linksVisible;
                                }));
                  })
              }, React.createElement("span", undefined, t(undefined, undefined, "more")), React.createElement("i", {
                    className: "fas fa-caret-down ml-2"
                  }), additionalLinks(match[0], links));
  } else {
    return null;
  }
}

var make = StudentTopNav__DropDown;

exports.str = str;
exports.t = t;
exports.handleToggle = handleToggle;
exports.additionalLinks = additionalLinks;
exports.make = make;
/* react Not a pure module */
