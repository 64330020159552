// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var I18n = require("../../shared/utils/I18n.bs.js");
var $$Array = require("rescript/lib/js/array.js");
var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var FaIcon = require("../../shared/components/FaIcon.bs.js");
var Js_exn = require("rescript/lib/js/js_exn.js");
var $$String = require("rescript/lib/js/string.js");
var Js_dict = require("rescript/lib/js/js_dict.js");
var Js_json = require("rescript/lib/js/js_json.js");
var Js_option = require("rescript/lib/js/js_option.js");
var ArrayUtils = require("../../shared/utils/ArrayUtils.bs.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Pagination = require("../../shared/utils/Pagination.bs.js");
var ReactUtils = require("../../shared/utils/ReactUtils.bs.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var OptionUtils = require("../../shared/utils/OptionUtils.bs.js");
var GraphqlQuery = require("../../shared/utils/GraphqlQuery.bs.js");
var LoadingSpinner = require("../../shared/components/LoadingSpinner.bs.js");
var SkeletonLoading = require("../../shared/components/SkeletonLoading.bs.js");
var MultiselectDropdown = require("../../packages/multiselect-dropdown/src/components/MultiselectDropdown.bs.js");
var Notifications__Entry = require("../types/Notifications__Entry.bs.js");
var Notifications__EntryCard = require("./Notifications__EntryCard.bs.js");
var WebPushSubscriptionManager = require("../../shared/WebPushSubscriptionManager.bs.js");

function str(prim) {
  return prim;
}

var partial_arg = "components.Notifications__List";

function t(param, param$1, param$2) {
  return I18n.t(partial_arg, param, param$1, param$2);
}

function eventName($$event) {
  if ($$event === "PostCreated") {
    return t(undefined, undefined, "filter.events.post_created_text");
  } else {
    return t(undefined, undefined, "filter.events.topic_created_text");
  }
}

var ppx_printed_query = "mutation MarkAllNotificationsMutation  {\nmarkAllNotifications  {\nsuccess  \n}\n\n}\n";

function parse(value) {
  var value$1 = Js_option.getExn(Js_json.decodeObject(value));
  var value$2 = Js_dict.get(value$1, "markAllNotifications");
  var tmp;
  if (value$2 !== undefined) {
    var value$3 = Js_option.getExn(Js_json.decodeObject(Caml_option.valFromOption(value$2)));
    var value$4 = Js_dict.get(value$3, "success");
    var tmp$1;
    if (value$4 !== undefined) {
      var value$5 = Caml_option.valFromOption(value$4);
      var value$6 = Js_json.decodeBoolean(value$5);
      tmp$1 = value$6 !== undefined ? value$6 : Js_exn.raiseError("graphql_ppx: Expected boolean, got " + JSON.stringify(value$5));
    } else {
      tmp$1 = Js_exn.raiseError("graphql_ppx: Field success on type MarkAllNotificationsPayload is missing");
    }
    tmp = {
      success: tmp$1
    };
  } else {
    tmp = Js_exn.raiseError("graphql_ppx: Field markAllNotifications on type Mutation is missing");
  }
  return {
          markAllNotifications: tmp
        };
}

function make(param) {
  return {
          query: ppx_printed_query,
          variables: null,
          parse: parse
        };
}

function makeWithVariables(param) {
  return {
          query: ppx_printed_query,
          variables: null,
          parse: parse
        };
}

function makeVariables(param) {
  return null;
}

function definition_2(graphql_ppx_use_json_variables_fn) {
  return 0;
}

var definition = [
  parse,
  ppx_printed_query,
  definition_2
];

function ret_type(f) {
  return {};
}

var MT_Ret = {};

var MarkAllNotificationsQuery = {
  ppx_printed_query: ppx_printed_query,
  query: ppx_printed_query,
  parse: parse,
  make: make,
  makeWithVariables: makeWithVariables,
  makeVariables: makeVariables,
  definition: definition,
  ret_type: ret_type,
  MT_Ret: MT_Ret
};

var Item = {};

var Pagination$1 = Pagination.Make(Item);

function updateNotification(id, notifications) {
  return notifications.map(function (entry) {
              if (Notifications__Entry.id(entry) === id) {
                return Notifications__Entry.markAsRead(entry);
              } else {
                return entry;
              }
            });
}

function markAllNotifications(notifications) {
  return notifications.map(function (entry) {
              if (Belt_Option.isNone(Notifications__Entry.readAt(entry))) {
                return Notifications__Entry.markAsRead(entry);
              } else {
                return entry;
              }
            });
}

function reducer(state, action) {
  if (typeof action === "number") {
    switch (action) {
      case /* UnsetSearchString */0 :
          var init = state.filter;
          return {
                  loading: state.loading,
                  entries: state.entries,
                  filterString: "",
                  filter: {
                    event: init.event,
                    title: undefined,
                    status: init.status
                  },
                  saving: state.saving,
                  totalEntriesCount: state.totalEntriesCount
                };
      case /* BeginLoadingMore */1 :
          return {
                  loading: /* LoadingMore */2,
                  entries: state.entries,
                  filterString: state.filterString,
                  filter: state.filter,
                  saving: state.saving,
                  totalEntriesCount: state.totalEntriesCount
                };
      case /* BeginReloading */2 :
          return {
                  loading: /* Reloading */1,
                  entries: state.entries,
                  filterString: state.filterString,
                  filter: state.filter,
                  saving: state.saving,
                  totalEntriesCount: state.totalEntriesCount
                };
      case /* ClearStatus */3 :
          var init$1 = state.filter;
          return {
                  loading: state.loading,
                  entries: state.entries,
                  filterString: "",
                  filter: {
                    event: init$1.event,
                    title: init$1.title,
                    status: undefined
                  },
                  saving: state.saving,
                  totalEntriesCount: state.totalEntriesCount
                };
      case /* ClearEvent */4 :
          var init$2 = state.filter;
          return {
                  loading: state.loading,
                  entries: state.entries,
                  filterString: "",
                  filter: {
                    event: undefined,
                    title: init$2.title,
                    status: init$2.status
                  },
                  saving: state.saving,
                  totalEntriesCount: state.totalEntriesCount
                };
      case /* SetSaving */5 :
          return {
                  loading: state.loading,
                  entries: state.entries,
                  filterString: state.filterString,
                  filter: state.filter,
                  saving: true,
                  totalEntriesCount: state.totalEntriesCount
                };
      case /* ClearSaving */6 :
          return {
                  loading: state.loading,
                  entries: state.entries,
                  filterString: state.filterString,
                  filter: state.filter,
                  saving: false,
                  totalEntriesCount: state.totalEntriesCount
                };
      case /* MarkAllNotifications */7 :
          var entries = state.entries;
          var tmp;
          tmp = typeof entries === "number" ? /* Unloaded */0 : (
              entries.TAG === /* PartiallyLoaded */0 ? ({
                    TAG: /* PartiallyLoaded */0,
                    _0: markAllNotifications(entries._0),
                    _1: entries._1
                  }) : ({
                    TAG: /* FullyLoaded */1,
                    _0: markAllNotifications(entries._0)
                  })
            );
          return {
                  loading: state.loading,
                  entries: tmp,
                  filterString: state.filterString,
                  filter: state.filter,
                  saving: false,
                  totalEntriesCount: Belt_Option.isSome(state.filter.status) ? 0 : state.totalEntriesCount
                };
      
    }
  } else {
    switch (action.TAG | 0) {
      case /* SetSearchString */0 :
          var init$3 = state.filter;
          return {
                  loading: state.loading,
                  entries: state.entries,
                  filterString: "",
                  filter: {
                    event: init$3.event,
                    title: action._0,
                    status: init$3.status
                  },
                  saving: state.saving,
                  totalEntriesCount: state.totalEntriesCount
                };
      case /* UpdateFilterString */1 :
          return {
                  loading: state.loading,
                  entries: state.entries,
                  filterString: action._0,
                  filter: state.filter,
                  saving: state.saving,
                  totalEntriesCount: state.totalEntriesCount
                };
      case /* LoadNotifications */2 :
          var newTopics = action._2;
          var match = state.loading;
          var updatedTopics = match >= 2 ? newTopics.concat(Curry._1(Pagination$1.toArray, state.entries)) : newTopics;
          return {
                  loading: /* NotLoading */0,
                  entries: Curry._3(Pagination$1.make, updatedTopics, action._1, action._0),
                  filterString: state.filterString,
                  filter: state.filter,
                  saving: state.saving,
                  totalEntriesCount: action._3
                };
      case /* SetStatus */3 :
          var init$4 = state.filter;
          return {
                  loading: state.loading,
                  entries: state.entries,
                  filterString: "",
                  filter: {
                    event: init$4.event,
                    title: init$4.title,
                    status: action._0
                  },
                  saving: state.saving,
                  totalEntriesCount: state.totalEntriesCount
                };
      case /* SetEvent */4 :
          var init$5 = state.filter;
          return {
                  loading: state.loading,
                  entries: state.entries,
                  filterString: "",
                  filter: {
                    event: action._0,
                    title: init$5.title,
                    status: init$5.status
                  },
                  saving: state.saving,
                  totalEntriesCount: state.totalEntriesCount
                };
      case /* MarkNotification */5 :
          var id = action._0;
          return {
                  loading: state.loading,
                  entries: Curry._2(Pagination$1.update, state.entries, (function (param) {
                          return updateNotification(id, param);
                        })),
                  filterString: state.filterString,
                  filter: state.filter,
                  saving: state.saving,
                  totalEntriesCount: Belt_Option.isSome(state.filter.status) ? state.totalEntriesCount - 1 | 0 : state.totalEntriesCount
                };
      
    }
  }
}

var ppx_printed_query$1 = "query NotificationsFromNotificationsListQuery($search: String, $after: String, $event: NotificationEvent, $status: NotificationStatus)  {\nnotifications(event: $event, search: $search, first: 10, after: $after, status: $status)  {\nnodes  {\nactor  {\nid  \nname  \ntitle  \navatarUrl  \n}\n\ncreatedAt  \nevent  \nid  \nmessage  \nnotifiableId  \nnotifiableType  \nreadAt  \n}\n\npageInfo  {\nendCursor  \nhasNextPage  \n}\n\ntotalCount  \n}\n\n}\n";

function parse$1(value) {
  var value$1 = Js_option.getExn(Js_json.decodeObject(value));
  var value$2 = Js_dict.get(value$1, "notifications");
  var tmp;
  if (value$2 !== undefined) {
    var value$3 = Js_option.getExn(Js_json.decodeObject(Caml_option.valFromOption(value$2)));
    var value$4 = Js_dict.get(value$3, "nodes");
    var value$5 = Js_dict.get(value$3, "pageInfo");
    var tmp$1;
    if (value$5 !== undefined) {
      var value$6 = Js_option.getExn(Js_json.decodeObject(Caml_option.valFromOption(value$5)));
      var value$7 = Js_dict.get(value$6, "endCursor");
      var tmp$2;
      if (value$7 !== undefined) {
        var value$8 = Caml_option.valFromOption(value$7);
        var match = Js_json.decodeNull(value$8);
        if (match !== undefined) {
          tmp$2 = undefined;
        } else {
          var value$9 = Js_json.decodeString(value$8);
          tmp$2 = value$9 !== undefined ? value$9 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$8));
        }
      } else {
        tmp$2 = undefined;
      }
      var value$10 = Js_dict.get(value$6, "hasNextPage");
      var tmp$3;
      if (value$10 !== undefined) {
        var value$11 = Caml_option.valFromOption(value$10);
        var value$12 = Js_json.decodeBoolean(value$11);
        tmp$3 = value$12 !== undefined ? value$12 : Js_exn.raiseError("graphql_ppx: Expected boolean, got " + JSON.stringify(value$11));
      } else {
        tmp$3 = Js_exn.raiseError("graphql_ppx: Field hasNextPage on type PageInfo is missing");
      }
      tmp$1 = {
        endCursor: tmp$2,
        hasNextPage: tmp$3
      };
    } else {
      tmp$1 = Js_exn.raiseError("graphql_ppx: Field pageInfo on type NotificationConnection is missing");
    }
    var value$13 = Js_dict.get(value$3, "totalCount");
    var tmp$4;
    if (value$13 !== undefined) {
      var value$14 = Caml_option.valFromOption(value$13);
      var value$15 = Js_json.decodeNumber(value$14);
      tmp$4 = value$15 !== undefined ? value$15 | 0 : Js_exn.raiseError("graphql_ppx: Expected int, got " + JSON.stringify(value$14));
    } else {
      tmp$4 = Js_exn.raiseError("graphql_ppx: Field totalCount on type NotificationConnection is missing");
    }
    tmp = {
      nodes: value$4 !== undefined ? Js_option.getExn(Js_json.decodeArray(Caml_option.valFromOption(value$4))).map(function (value) {
              var value$1 = Js_option.getExn(Js_json.decodeObject(value));
              var value$2 = Js_dict.get(value$1, "actor");
              var tmp;
              if (value$2 !== undefined) {
                var value$3 = Caml_option.valFromOption(value$2);
                var match = Js_json.decodeNull(value$3);
                if (match !== undefined) {
                  tmp = undefined;
                } else {
                  var value$4 = Js_option.getExn(Js_json.decodeObject(value$3));
                  var value$5 = Js_dict.get(value$4, "id");
                  var tmp$1;
                  if (value$5 !== undefined) {
                    var value$6 = Caml_option.valFromOption(value$5);
                    var value$7 = Js_json.decodeString(value$6);
                    tmp$1 = value$7 !== undefined ? value$7 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$6));
                  } else {
                    tmp$1 = Js_exn.raiseError("graphql_ppx: Field id on type User is missing");
                  }
                  var value$8 = Js_dict.get(value$4, "name");
                  var tmp$2;
                  if (value$8 !== undefined) {
                    var value$9 = Caml_option.valFromOption(value$8);
                    var value$10 = Js_json.decodeString(value$9);
                    tmp$2 = value$10 !== undefined ? value$10 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$9));
                  } else {
                    tmp$2 = Js_exn.raiseError("graphql_ppx: Field name on type User is missing");
                  }
                  var value$11 = Js_dict.get(value$4, "title");
                  var tmp$3;
                  if (value$11 !== undefined) {
                    var value$12 = Caml_option.valFromOption(value$11);
                    var value$13 = Js_json.decodeString(value$12);
                    tmp$3 = value$13 !== undefined ? value$13 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$12));
                  } else {
                    tmp$3 = Js_exn.raiseError("graphql_ppx: Field title on type User is missing");
                  }
                  var value$14 = Js_dict.get(value$4, "avatarUrl");
                  var tmp$4;
                  if (value$14 !== undefined) {
                    var value$15 = Caml_option.valFromOption(value$14);
                    var match$1 = Js_json.decodeNull(value$15);
                    if (match$1 !== undefined) {
                      tmp$4 = undefined;
                    } else {
                      var value$16 = Js_json.decodeString(value$15);
                      tmp$4 = value$16 !== undefined ? value$16 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$15));
                    }
                  } else {
                    tmp$4 = undefined;
                  }
                  tmp = {
                    id: tmp$1,
                    name: tmp$2,
                    title: tmp$3,
                    avatarUrl: tmp$4
                  };
                }
              } else {
                tmp = undefined;
              }
              var value$17 = Js_dict.get(value$1, "createdAt");
              var value$18 = Js_dict.get(value$1, "event");
              var tmp$5;
              if (value$18 !== undefined) {
                var value$19 = Caml_option.valFromOption(value$18);
                var value$20 = Js_json.decodeString(value$19);
                if (value$20 !== undefined) {
                  switch (value$20) {
                    case "PostCreated" :
                        tmp$5 = "PostCreated";
                        break;
                    case "TopicCreated" :
                        tmp$5 = "TopicCreated";
                        break;
                    default:
                      tmp$5 = Js_exn.raiseError("graphql_ppx: Unknown enum variant for NotificationEvent: " + value$20);
                  }
                } else {
                  tmp$5 = Js_exn.raiseError("graphql_ppx: Expected enum value for NotificationEvent, got " + JSON.stringify(value$19));
                }
              } else {
                tmp$5 = Js_exn.raiseError("graphql_ppx: Field event on type Notification is missing");
              }
              var value$21 = Js_dict.get(value$1, "id");
              var tmp$6;
              if (value$21 !== undefined) {
                var value$22 = Caml_option.valFromOption(value$21);
                var value$23 = Js_json.decodeString(value$22);
                tmp$6 = value$23 !== undefined ? value$23 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$22));
              } else {
                tmp$6 = Js_exn.raiseError("graphql_ppx: Field id on type Notification is missing");
              }
              var value$24 = Js_dict.get(value$1, "message");
              var tmp$7;
              if (value$24 !== undefined) {
                var value$25 = Caml_option.valFromOption(value$24);
                var value$26 = Js_json.decodeString(value$25);
                tmp$7 = value$26 !== undefined ? value$26 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$25));
              } else {
                tmp$7 = Js_exn.raiseError("graphql_ppx: Field message on type Notification is missing");
              }
              var value$27 = Js_dict.get(value$1, "notifiableId");
              var tmp$8;
              if (value$27 !== undefined) {
                var value$28 = Caml_option.valFromOption(value$27);
                var match$2 = Js_json.decodeNull(value$28);
                if (match$2 !== undefined) {
                  tmp$8 = undefined;
                } else {
                  var value$29 = Js_json.decodeString(value$28);
                  tmp$8 = value$29 !== undefined ? value$29 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$28));
                }
              } else {
                tmp$8 = undefined;
              }
              var value$30 = Js_dict.get(value$1, "notifiableType");
              var tmp$9;
              if (value$30 !== undefined) {
                var value$31 = Caml_option.valFromOption(value$30);
                var match$3 = Js_json.decodeNull(value$31);
                if (match$3 !== undefined) {
                  tmp$9 = undefined;
                } else {
                  var value$32 = Js_json.decodeString(value$31);
                  tmp$9 = value$32 !== undefined ? value$32 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$31));
                }
              } else {
                tmp$9 = undefined;
              }
              var value$33 = Js_dict.get(value$1, "readAt");
              var tmp$10;
              if (value$33 !== undefined) {
                var value$34 = Caml_option.valFromOption(value$33);
                var match$4 = Js_json.decodeNull(value$34);
                tmp$10 = match$4 !== undefined ? undefined : Caml_option.some(value$34);
              } else {
                tmp$10 = undefined;
              }
              return {
                      actor: tmp,
                      createdAt: value$17 !== undefined ? Caml_option.valFromOption(value$17) : Js_exn.raiseError("graphql_ppx: Field createdAt on type Notification is missing"),
                      event: tmp$5,
                      id: tmp$6,
                      message: tmp$7,
                      notifiableId: tmp$8,
                      notifiableType: tmp$9,
                      readAt: tmp$10
                    };
            }) : Js_exn.raiseError("graphql_ppx: Field nodes on type NotificationConnection is missing"),
      pageInfo: tmp$1,
      totalCount: tmp$4
    };
  } else {
    tmp = Js_exn.raiseError("graphql_ppx: Field notifications on type Query is missing");
  }
  return {
          notifications: tmp
        };
}

function json_of_NotificationEvent(value) {
  if (value === "PostCreated") {
    return "PostCreated";
  } else {
    return "TopicCreated";
  }
}

function json_of_NotificationStatus(value) {
  if (value === "Read") {
    return "Read";
  } else {
    return "Unread";
  }
}

function make$1(search, after, $$event, status, param) {
  return {
          query: ppx_printed_query$1,
          variables: Js_dict.fromArray([
                  [
                    "search",
                    search !== undefined ? search : null
                  ],
                  [
                    "after",
                    after !== undefined ? after : null
                  ],
                  [
                    "event",
                    $$event !== undefined ? json_of_NotificationEvent($$event) : null
                  ],
                  [
                    "status",
                    status !== undefined ? json_of_NotificationStatus(status) : null
                  ]
                ].filter(function (param) {
                    return !Js_json.test(param[1], /* Null */5);
                  })),
          parse: parse$1
        };
}

function makeWithVariables$1(variables) {
  var search = variables.search;
  var after = variables.after;
  var $$event = variables.event;
  var status = variables.status;
  return {
          query: ppx_printed_query$1,
          variables: Js_dict.fromArray([
                  [
                    "search",
                    search !== undefined ? search : null
                  ],
                  [
                    "after",
                    after !== undefined ? after : null
                  ],
                  [
                    "event",
                    $$event !== undefined ? json_of_NotificationEvent($$event) : null
                  ],
                  [
                    "status",
                    status !== undefined ? json_of_NotificationStatus(status) : null
                  ]
                ].filter(function (param) {
                    return !Js_json.test(param[1], /* Null */5);
                  })),
          parse: parse$1
        };
}

function makeVariables$1(search, after, $$event, status, param) {
  return Js_dict.fromArray([
                [
                  "search",
                  search !== undefined ? search : null
                ],
                [
                  "after",
                  after !== undefined ? after : null
                ],
                [
                  "event",
                  $$event !== undefined ? json_of_NotificationEvent($$event) : null
                ],
                [
                  "status",
                  status !== undefined ? json_of_NotificationStatus(status) : null
                ]
              ].filter(function (param) {
                  return !Js_json.test(param[1], /* Null */5);
                }));
}

function definition_2$1(graphql_ppx_use_json_variables_fn, search, after, $$event, status, param) {
  return Curry._1(graphql_ppx_use_json_variables_fn, Js_dict.fromArray([
                    [
                      "search",
                      search !== undefined ? search : null
                    ],
                    [
                      "after",
                      after !== undefined ? after : null
                    ],
                    [
                      "event",
                      $$event !== undefined ? json_of_NotificationEvent($$event) : null
                    ],
                    [
                      "status",
                      status !== undefined ? json_of_NotificationStatus(status) : null
                    ]
                  ].filter(function (param) {
                      return !Js_json.test(param[1], /* Null */5);
                    })));
}

var definition$1 = [
  parse$1,
  ppx_printed_query$1,
  definition_2$1
];

function ret_type$1(f) {
  return {};
}

var MT_Ret$1 = {};

var NotificationsQuery = {
  ppx_printed_query: ppx_printed_query$1,
  query: ppx_printed_query$1,
  parse: parse$1,
  json_of_NotificationEvent: json_of_NotificationEvent,
  json_of_NotificationStatus: json_of_NotificationStatus,
  make: make$1,
  makeWithVariables: makeWithVariables$1,
  makeVariables: makeVariables$1,
  definition: definition$1,
  ret_type: ret_type$1,
  MT_Ret: MT_Ret$1
};

function markAllNotifications$1(send, $$event) {
  $$event.preventDefault();
  Curry._1(send, /* SetSaving */5);
  GraphqlQuery.sendQuery(undefined, make(undefined)).then(function (response) {
          if (response.markAllNotifications.success) {
            Curry._1(send, /* MarkAllNotifications */7);
          } else {
            Curry._1(send, /* ClearSaving */6);
          }
          return Promise.resolve(undefined);
        }).catch(function (param) {
        Curry._1(send, /* ClearSaving */6);
        return Promise.resolve(undefined);
      });
  
}

function getEntries(send, cursor, filter) {
  GraphqlQuery.sendQuery(undefined, make$1(filter.title, cursor, filter.event, filter.status, undefined)).then(function (response) {
        var newNotifications = response.notifications.nodes.map(Notifications__Entry.makeFromJS);
        Curry._1(send, {
              TAG: /* LoadNotifications */2,
              _0: response.notifications.pageInfo.endCursor,
              _1: response.notifications.pageInfo.hasNextPage,
              _2: newNotifications,
              _3: response.notifications.totalCount
            });
        return Promise.resolve(undefined);
      });
  
}

function markNotification(send, notificationId) {
  return Curry._1(send, {
              TAG: /* MarkNotification */5,
              _0: notificationId
            });
}

function reloadEntries(state, send) {
  Curry._1(send, /* BeginReloading */2);
  return getEntries(send, undefined, state.filter);
}

function computeInitialState(param) {
  return {
          loading: /* NotLoading */0,
          entries: /* Unloaded */0,
          filterString: "",
          filter: {
            event: undefined,
            title: undefined,
            status: "Unread"
          },
          saving: false,
          totalEntriesCount: 0
        };
}

function entriesList(caption, entries, send) {
  return React.createElement("div", undefined, React.createElement("div", {
                  className: "text-xs text-gray-800 px-4 lg:px-8"
                }, caption), React.createElement("div", undefined, entries.map(function (entry) {
                      return React.createElement(Notifications__EntryCard.make, {
                                  entry: entry,
                                  markNotificationCB: (function (param) {
                                      return Curry._1(send, {
                                                  TAG: /* MarkNotification */5,
                                                  _0: param
                                                });
                                    }),
                                  key: Notifications__Entry.id(entry)
                                });
                    })));
}

function entriesLoadedData(totoalNotificationsCount, loadedNotificaionsCount) {
  return React.createElement("div", {
              className: "inline-block mt-2 mx-auto text-gray-800 text-xs px-2 text-center font-semibold"
            }, totoalNotificationsCount === loadedNotificaionsCount ? t([[
                      "total_notifications",
                      String(totoalNotificationsCount)
                    ]], undefined, "notifications_fully_loaded_text") : t([
                    [
                      "total_notifications",
                      String(totoalNotificationsCount)
                    ],
                    [
                      "loaded_notifications_count",
                      String(loadedNotificaionsCount)
                    ]
                  ], undefined, "notifications_partially_loaded_text"));
}

function label(s) {
  switch (s.TAG | 0) {
    case /* Event */0 :
        return t(undefined, undefined, "filter.label.event");
    case /* Status */1 :
        return t(undefined, undefined, "filter.label.status");
    case /* Title */2 :
        return t(undefined, undefined, "filter.label.title");
    
  }
}

function value(s) {
  switch (s.TAG | 0) {
    case /* Event */0 :
        return eventName(s._0);
    case /* Status */1 :
        var key = s._0 === "Read" ? "read" : "unread";
        return t(undefined, undefined, "filter.status." + key);
    case /* Title */2 :
        return s._0;
    
  }
}

var searchString = value;

function color(t) {
  switch (t.TAG | 0) {
    case /* Event */0 :
        return "blue";
    case /* Status */1 :
        if (t._0 === "Read") {
          return "green";
        } else {
          return "orange";
        }
    case /* Title */2 :
        return "gray";
    
  }
}

function $$event($$event$1) {
  return {
          TAG: /* Event */0,
          _0: $$event$1
        };
}

function title(search) {
  return {
          TAG: /* Title */2,
          _0: search
        };
}

function status(status$1) {
  return {
          TAG: /* Status */1,
          _0: status$1
        };
}

var Selectable = {
  label: label,
  value: value,
  searchString: searchString,
  color: color,
  $$event: $$event,
  title: title,
  status: status
};

var Multiselect = MultiselectDropdown.Make(Selectable);

function unselected(state) {
  var eventFilters = $$Array.map($$event, [
        "TopicCreated",
        "PostCreated"
      ]);
  var trimmedFilterString = $$String.trim(state.filterString);
  var title = trimmedFilterString === "" ? [] : [{
        TAG: /* Title */2,
        _0: trimmedFilterString
      }];
  var status = Belt_Option.mapWithDefault(state.filter.status, [
          "Read",
          "Unread"
        ], (function (u) {
            if (u === "Read") {
              return ["Unread"];
            } else {
              return ["Read"];
            }
          })).map(function (s) {
        return {
                TAG: /* Status */1,
                _0: s
              };
      });
  return eventFilters.concat(title).concat(status);
}

function defaultOptions(param) {
  return $$Array.map((function (s) {
                return {
                        TAG: /* Status */1,
                        _0: s
                      };
              }), [
              "Read",
              "Unread"
            ]);
}

function selected(state) {
  var selectedEventFilters = Belt_Option.mapWithDefault(state.filter.event, [], (function (e) {
          return [{
                    TAG: /* Event */0,
                    _0: e
                  }];
        }));
  var selectedSearchString = OptionUtils.mapWithDefault((function (title) {
          return [{
                    TAG: /* Title */2,
                    _0: title
                  }];
        }), [], state.filter.title);
  var status = Belt_Option.mapWithDefault(state.filter.status, [], (function (u) {
          return [{
                    TAG: /* Status */1,
                    _0: u
                  }];
        }));
  return selectedEventFilters.concat(selectedSearchString).concat(status);
}

function onSelectFilter(send, selectable) {
  switch (selectable.TAG | 0) {
    case /* Event */0 :
        return Curry._1(send, {
                    TAG: /* SetEvent */4,
                    _0: selectable._0
                  });
    case /* Status */1 :
        return Curry._1(send, {
                    TAG: /* SetStatus */3,
                    _0: selectable._0
                  });
    case /* Title */2 :
        return Curry._1(send, {
                    TAG: /* SetSearchString */0,
                    _0: selectable._0
                  });
    
  }
}

function onDeselectFilter(send, selectable) {
  switch (selectable.TAG | 0) {
    case /* Event */0 :
        return Curry._1(send, /* ClearEvent */4);
    case /* Status */1 :
        return Curry._1(send, /* ClearStatus */3);
    case /* Title */2 :
        return Curry._1(send, /* UnsetSearchString */0);
    
  }
}

function showEntries(entries, state, send) {
  var filteredEntries = Belt_Option.mapWithDefault(state.filter.status, entries, (function (u) {
          if (u === "Read") {
            return entries.filter(function (e) {
                        return Belt_Option.isSome(Notifications__Entry.readAt(e));
                      });
          } else {
            return entries.filter(function (e) {
                        return Belt_Option.isNone(Notifications__Entry.readAt(e));
                      });
          }
        }));
  var dates = ArrayUtils.distinct(filteredEntries.map(function (e) {
            return Notifications__Entry.createdAt(e).toDateString();
          }));
  if (ArrayUtils.isEmpty(filteredEntries)) {
    return React.createElement("div", {
                className: "flex flex-col mx-auto bg-white rounded-md border p-6 justify-center items-center"
              }, React.createElement(FaIcon.make, {
                    classes: "fas fa-comments text-5xl text-gray-400"
                  }), React.createElement("h4", {
                    className: "mt-3 text-base md:text-lg text-center font-semibold"
                  }, t(undefined, undefined, "empty_notifications")));
  } else {
    return React.createElement("div", undefined, dates.map(function (d) {
                    var entries = filteredEntries.filter(function (e) {
                          return Notifications__Entry.createdAt(e).toDateString() === d;
                        });
                    var heading = d === new Date().toDateString() ? "Today" : d;
                    return ReactUtils.nullIf(entriesList(heading, entries, send), ArrayUtils.isEmpty(entries));
                  }), React.createElement("div", {
                    className: "text-center pb-4"
                  }, entriesLoadedData(state.totalEntriesCount, filteredEntries.length)));
  }
}

function markAllNotificationsButton(state, send, entries) {
  var disabled = Belt_Array.every(entries, (function (e) {
          return Belt_Option.isSome(Notifications__Entry.readAt(e));
        }));
  return ReactUtils.nullIf(React.createElement("div", {
                  className: "flex w-full justify-end px-4 lg:px-8 -mb-5"
                }, React.createElement("button", {
                      className: "font-semibold text-xs underline px-2 py-1 rounded text-gray-800 hover:text-primary-500 hover:bg-gray-200 transition ease-in-out duration-150",
                      disabled: disabled || state.saving,
                      onClick: (function (param) {
                          return markAllNotifications$1(send, param);
                        })
                    }, t(undefined, undefined, "mark_all_as_read_button"))), ArrayUtils.isEmpty(entries));
}

function Notifications__List(Props) {
  var match = React.useReducer(reducer, {
        loading: /* NotLoading */0,
        entries: /* Unloaded */0,
        filterString: "",
        filter: {
          event: undefined,
          title: undefined,
          status: "Unread"
        },
        saving: false,
        totalEntriesCount: 0
      });
  var send = match[1];
  var state = match[0];
  React.useEffect((function () {
          reloadEntries(state, send);
          
        }), [state.filter]);
  var entries = state.entries;
  var tmp;
  if (typeof entries === "number") {
    tmp = React.createElement("div", {
          className: "px-2 lg:px-8"
        }, SkeletonLoading.multiple(10, SkeletonLoading.card(undefined, undefined)));
  } else if (entries.TAG === /* PartiallyLoaded */0) {
    var cursor = entries._1;
    var entries$1 = entries._0;
    var match$1 = state.loading;
    var tmp$1;
    switch (match$1) {
      case /* NotLoading */0 :
          tmp$1 = React.createElement("div", {
                className: "px-4 lg:px-8 pb-6"
              }, React.createElement("button", {
                    className: "btn btn-primary-ghost cursor-pointer w-full",
                    onClick: (function (param) {
                        Curry._1(send, /* BeginLoadingMore */1);
                        return getEntries(send, cursor, state.filter);
                      })
                  }, t(undefined, undefined, "button_load_more")));
          break;
      case /* Reloading */1 :
          tmp$1 = null;
          break;
      case /* LoadingMore */2 :
          tmp$1 = React.createElement("div", {
                className: "px-2 lg:px-8"
              }, SkeletonLoading.multiple(3, SkeletonLoading.card(undefined, undefined)));
          break;
      
    }
    tmp = React.createElement("div", undefined, markAllNotificationsButton(state, send, entries$1), showEntries(entries$1, state, send), tmp$1);
  } else {
    var entries$2 = entries._0;
    tmp = React.createElement("div", undefined, markAllNotificationsButton(state, send, entries$2), showEntries(entries$2, state, send));
  }
  var match$2 = state.entries;
  var tmp$2;
  if (typeof match$2 === "number") {
    tmp$2 = null;
  } else {
    var match$3 = state.loading;
    var loading = match$3 === 1;
    tmp$2 = React.createElement(LoadingSpinner.make, {
          loading: loading
        });
  }
  return React.createElement("div", undefined, React.createElement("div", {
                  className: "md:flex md:flex-row space-y-2 md:space-y-0 md:space-x-2 md:justify-between md:items-center pt-4 px-4 lg:px-8 bg-gray-100"
                }, React.createElement("div", {
                      className: "font-bold text-sm md:text-xl"
                    }, "Benachrichtigungen"), React.createElement(WebPushSubscriptionManager.make, {})), React.createElement("div", {
                  className: "w-full bg-gray-100 border-b sticky top-0 z-30 px-4 lg:px-8 py-3"
                }, React.createElement("label", {
                      className: "block text-tiny font-semibold uppercase pl-px text-left",
                      htmlFor: "search_notifcations"
                    }, t(undefined, undefined, "filter.input_label")), React.createElement(Multiselect.make, {
                      id: "search_notifcations",
                      placeholder: t(undefined, undefined, "filter.input_placeholder"),
                      onChange: (function (filterString) {
                          return Curry._1(send, {
                                      TAG: /* UpdateFilterString */1,
                                      _0: filterString
                                    });
                        }),
                      value: state.filterString,
                      unselected: unselected(state),
                      selected: selected(state),
                      onSelect: (function (param) {
                          return onSelectFilter(send, param);
                        }),
                      onDeselect: (function (param) {
                          return onDeselectFilter(send, param);
                        }),
                      hint: t(undefined, undefined, "filter.input_hint"),
                      defaultOptions: defaultOptions(undefined)
                    })), React.createElement("div", {
                  className: "mt-4",
                  id: "entries"
                }, tmp), tmp$2);
}

var make$2 = Notifications__List;

exports.str = str;
exports.t = t;
exports.eventName = eventName;
exports.MarkAllNotificationsQuery = MarkAllNotificationsQuery;
exports.Item = Item;
exports.Pagination = Pagination$1;
exports.updateNotification = updateNotification;
exports.reducer = reducer;
exports.NotificationsQuery = NotificationsQuery;
exports.markAllNotifications = markAllNotifications$1;
exports.getEntries = getEntries;
exports.markNotification = markNotification;
exports.reloadEntries = reloadEntries;
exports.computeInitialState = computeInitialState;
exports.entriesList = entriesList;
exports.entriesLoadedData = entriesLoadedData;
exports.Selectable = Selectable;
exports.Multiselect = Multiselect;
exports.unselected = unselected;
exports.defaultOptions = defaultOptions;
exports.selected = selected;
exports.onSelectFilter = onSelectFilter;
exports.onDeselectFilter = onDeselectFilter;
exports.showEntries = showEntries;
exports.markAllNotificationsButton = markAllNotificationsButton;
exports.make = make$2;
/* Pagination Not a pure module */
