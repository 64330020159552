// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var I18n = require("../../../shared/utils/I18n.bs.js");
var User = require("../../../shared/types/User.bs.js");
var React = require("react");
var Avatar = require("../../../shared/Avatar.bs.js");
var FaIcon = require("../../../shared/components/FaIcon.bs.js");
var Dropdown = require("../../../shared/components/Dropdown.bs.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");

function str(prim) {
  return prim;
}

var partial_arg = "components.StudentTopNav__UserControls";

function t(param, param$1, param$2) {
  return I18n.t(partial_arg, param, param$1, param$2);
}

function showLink(icon, text, href) {
  return React.createElement("div", {
              key: href,
              className: "whitespace-nowrap"
            }, React.createElement("a", {
                  className: "cursor-pointer block p-3 text-xs font-semibold text-gray-900 border-b border-gray-200 bg-white hover:text-primary-500 hover:bg-gray-200",
                  href: href,
                  rel: "nofollow"
                }, React.createElement(FaIcon.make, {
                      classes: "fas fw fa-" + icon
                    }), React.createElement("span", {
                      className: "pl-2"
                    }, text)));
}

function links(param) {
  return [
          showLink("edit", t(undefined, undefined, "edit_profile"), "/user/edit"),
          showLink("power-off", t(undefined, undefined, "sign_out"), "/users/sign_out")
        ];
}

function selected(user) {
  return React.createElement("button", {
              className: "md:ml-2 h-10 w-10 rounded-full border border-gray-300 hover:border-primary-500",
              title: "Show user controls"
            }, Belt_Option.mapWithDefault(user, React.createElement(Avatar.make, {
                      name: "Unknown User",
                      className: "inline-block object-contain rounded-full text-tiny"
                    }), (function (u) {
                    return Belt_Option.mapWithDefault(User.avatarUrl(u), React.createElement(Avatar.make, {
                                    name: User.name(u),
                                    className: "inline-block object-contain rounded-full text-tiny"
                                  }), (function (src) {
                                  return React.createElement("img", {
                                              className: "inline-block object-contain rounded-full text-tiny",
                                              alt: User.name(u),
                                              src: src
                                            });
                                }));
                  })));
}

function StudentTopNav__UserControls(Props) {
  var user = Props.user;
  return React.createElement(Dropdown.make, {
              selected: selected(user),
              contents: links(undefined),
              right: true
            });
}

var make = StudentTopNav__UserControls;

exports.str = str;
exports.t = t;
exports.showLink = showLink;
exports.links = links;
exports.selected = selected;
exports.make = make;
/* User Not a pure module */
