// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Json_decode = require("../../../../@glennsl/bs-json/lib/ocaml/Json_decode.bs.js");

function title(t) {
  return t.title;
}

function url(t) {
  return t.url;
}

function decode(json) {
  return {
          title: Json_decode.field("title", Json_decode.string, json),
          url: Json_decode.field("url", Json_decode.string, json)
        };
}

function local(t) {
  return [
            "Admin",
            "Dashboard",
            "Coaches"
          ].includes(t.title);
}

exports.title = title;
exports.url = url;
exports.decode = decode;
exports.local = local;
/* No side effect */
