// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Caml_exceptions = require("rescript/lib/js/caml_exceptions.js");

var CSRFTokenMissing = /* @__PURE__ */Caml_exceptions.create("AuthenticityToken.CSRFTokenMissing");

var CSRFTokenEmpty = /* @__PURE__ */Caml_exceptions.create("AuthenticityToken.CSRFTokenEmpty");

function fromHead(param) {
  var metaTag = document.querySelector("meta[name='csrf-token']");
  if (metaTag == null) {
    throw {
          RE_EXN_ID: CSRFTokenMissing,
          Error: new Error()
        };
  }
  var token = metaTag.getAttribute("content");
  if (!(token == null)) {
    return token;
  }
  throw {
        RE_EXN_ID: CSRFTokenEmpty,
        Error: new Error()
      };
}

exports.CSRFTokenMissing = CSRFTokenMissing;
exports.CSRFTokenEmpty = CSRFTokenEmpty;
exports.fromHead = fromHead;
/* No side effect */
