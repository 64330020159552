// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var ArrayUtils = require("../utils/ArrayUtils.bs.js");
var Json_decode = require("../../@glennsl/bs-json/lib/ocaml/Json_decode.bs.js");

function id(t) {
  return t.id;
}

function name(t) {
  return t.name;
}

function avatarUrl(t) {
  return t.avatarUrl;
}

function title(t) {
  return t.title;
}

function decode(json) {
  return {
          id: Json_decode.field("id", Json_decode.string, json),
          name: Json_decode.field("name", Json_decode.string, json),
          avatarUrl: Json_decode.optional((function (param) {
                  return Json_decode.field("avatarUrl", Json_decode.string, param);
                }), json),
          title: Json_decode.field("title", Json_decode.string, json)
        };
}

function findById(id, proxies) {
  return ArrayUtils.unsafeFind((function (proxy) {
                return proxy.id === id;
              }), "Unable to find a User with ID " + id, proxies);
}

function make(id, name, avatarUrl, title) {
  return {
          id: id,
          name: name,
          avatarUrl: avatarUrl,
          title: title
        };
}

function makeFromJs(jsObject) {
  return make(jsObject.id, jsObject.name, jsObject.avatarUrl, jsObject.title);
}

exports.id = id;
exports.name = name;
exports.avatarUrl = avatarUrl;
exports.title = title;
exports.decode = decode;
exports.findById = findById;
exports.make = make;
exports.makeFromJs = makeFromJs;
/* ArrayUtils Not a pure module */
